import {
  ComponentStyleConfig,
  extendTheme,
  type Theme,
} from "@chakra-ui/react";

const containerOverride: ComponentStyleConfig = {
  baseStyle: {
    w: "100%",
    maxW: "1320px",
    mx: "auto",
  },
};

const theme = {
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, sans-serif",
    mono: "Open Sans, sans-serif",
  },
} satisfies Theme;

export default extendTheme(theme);
